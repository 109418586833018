body {
    background-color: #2c003e; /* Deep Purple */
    color: #f5e1da; /* Soft Pinkish Beige for text */
}

/* Pale and subtle header/footer color */
.header, .footer {
    background-color: #000; /* Pale Mauve */
}

.button {
    background-color: #a20056; /* Deep Magenta */
    color: #ffffff; /* White text */
}

.link {
    color: #ff4500; /* Orange Red for links */
}

/* Ensure the entire page takes up at least the full viewport height */
html, body {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
}

/* Flex container for content and footer */
body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Main content should grow to take up available space */
.content {
    flex: 1;
}

/* Sticky footer styling */
.footer {
    padding: 1rem;
    text-align: center;
    flex-shrink: 0;
}

